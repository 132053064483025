'use client';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { typograf } from '@/shared/utils/typograf';
import { AnimatedBg } from '@/widgets/socials-section/ui/AnimatedBg';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import TgIcon from '~/icons/tg.svg';
import VkIcon from '~/icons/vk.svg';

interface Props {
  className?: string;
}

export const SocialsSection: FC<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  return (
    <Container ref={ref} className={twMergeExt(cvaRoot(), className)} variant={'full'}>
      <div className={cvaContainer()}>
        <Title as={'h2'} trigger={inView} className={cvaHeading()}>
          <div className={'text-cMediumGreen'}>    @aloesmart —</div>
          <div>{typograf.execute('подписывайся на наши социальные сети')}</div>
        </Title>
        <div className={cvaDescr()}>
          Обзоры новинок, лайфхаки, правила по уходу за кожей, разборы ингредиентов и многое другое.
        </div>
        <div className={'mx-auto'}>
          <div className={cvaLinks()}>
            <Link
              className={cvaLink()}
              contentWrapperClassName={cvaLinkContent()}
              color={'darkGreyOnGrey'}
              size={'medium'}
              hoverEffect={'highlight'}
              href={'https://t.me/ALOEsmart'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Телеграм
              <TgIcon className={cvaLinkIcon()} />
            </Link>
            <Link
              className={cvaLink()}
              contentWrapperClassName={cvaLinkContent()}
              color={'darkGreyOnGrey'}
              size={'medium'}
              hoverEffect={'highlight'}
              href={'https://vk.com/aloesmart'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Вконтакте
              <VkIcon className={cvaLinkIcon()} />
            </Link>
          </div>
        </div>
      </div>

      <AnimatedBg />
    </Container>
  );
};

const cvaRoot = cva([
  'SocialsSection-cvaRoot',
  'relative',
  'overflow-hidden',
  'py-[16.5rem] sm:py-[19.7rem] mt-6.4 sm:my-[10.4rem] rounded-[4rem] sm:rounded-[6.4rem]',
  'bg-cLightGrey',
]);

const cvaContainer = cva([
  'SocialsSection-cvaContainer',
  'relative z-[1]',
  'max-w-[86.8rem]',
  'mx-auto',
  'text-center',
]);

const cvaHeading = cva(['SocialsSection-cvaHeading', 'font-secondary text-h1']);

const cvaDescr = cva([
  'SocialsSection-cvaDescr',
  'max-w-[28rem] sm:max-w-[53.6rem]',
  ' mt-2.4 sm:mt-4.8 mx-auto',
  'text-bodyL text-cBlack80',
]);

const cvaLinks = cva(['SocialsSection-cvaLinks', 'flex justify-center flex-wrap gap-0.8', 'mt-3.2 sm:mt-4.8']);

const cvaLink = cva(['SocialsSection-cvaLink', 'px-3.2', 'text-bodyMSemibold text-cBlack']);

const cvaLinkContent = cva(['SocialsSection-cvaLinkContent', 'flex items-center gap-0.8']);

const cvaLinkIcon = cva(['SocialsSection-cvaLinkIcon', 'w-2.4 h-2.4']);
