'use client';
import { FC, ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { usePrevious } from 'react-use';
import { twMergeExt } from '@/shared/utils/twMergeExt';

interface Props {
  className?: string;
  leftSliderActiveIndex: number;
  children?: ReactNode;
}

export const RightSliderChangeAnimation: FC<Props> = ({ className, leftSliderActiveIndex, children }) => {
  const prevActiveIndex = usePrevious(leftSliderActiveIndex);
  const direction = leftSliderActiveIndex > (prevActiveIndex ?? 0) ? 'right' : 'left';

  return (
    <AnimatePresence custom={direction} initial={false} mode={'popLayout'}>
      <motion.div
        key={leftSliderActiveIndex}
        className={twMergeExt(cvaRoot(), className)}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          duration: 0.3,
          ease: 'easeOut',
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const variants = {
  enter: (direction: 'right' | 'left') => ({
    opacity: 0,
    x: direction === 'right' ? '100%' : '-100%',
  }),
  center: {
    opacity: 1,
    x: '0%',
  },
  exit: (direction: 'right' | 'left') => ({
    opacity: 0,
    x: direction === 'right' ? '-100%' : '100%',
  }),
};

const cvaRoot = cva(['RightSliderChangeAnimation-cvaRoot', 'relative left-0 top-0 md:absolute', 'w-full md:h-full']);
