import(/* webpackMode: "eager" */ "/app/src/entities/catalog-category/assets/maskbar.svg");
;
import(/* webpackMode: "eager" */ "/app/src/entities/catalog-category/assets/miniformat.svg");
;
import(/* webpackMode: "eager", webpackExports: ["CatalogCategoryPreview"] */ "/app/src/entities/catalog-category/ui/CatalogCategoryPreview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CatalogCategoriesAnimation"] */ "/app/src/features/catalog-categories-animation/ui/CatalogCategoriesAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CatalogCategoriesSlider"] */ "/app/src/features/catalog-categories-slider/ui/CatalogCategoriesSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AboutFeatures"] */ "/app/src/widgets/about-section/ui/AboutFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AboutSection"] */ "/app/src/widgets/about-section/ui/AboutSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannersSlider"] */ "/app/src/widgets/banners-slider/ui/BannersSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlogPreviewSection"] */ "/app/src/widgets/blog-preview-section/ui/BlogPreviewSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BloggerTipsSection"] */ "/app/src/widgets/blogger-tips-section/ui/BloggerTipsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BonusProgramSection"] */ "/app/src/widgets/bonus-program-section/ui/BonusProgramSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandsMarqueeSection"] */ "/app/src/widgets/brands-marquee-section/ui/BrandsMarqueeSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GiftSection"] */ "/app/src/widgets/gift-section/ui/GiftSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurStoresSection"] */ "/app/src/widgets/our-stores-section/ui/OurStoresSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewsPreviewSection"] */ "/app/src/widgets/reviews-preview-section/ui/ReviewsPreviewSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocialsSection"] */ "/app/src/widgets/socials-section/ui/SocialsSection.tsx");
