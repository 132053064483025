'use client';
import { LazyImage } from '@/shared/ui/LazyImage';

import { cva } from 'class-variance-authority';
import { motion, useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import { useMedia } from 'react-use';

interface Props {
  scrollProgress?: number;
}

export const Cards: FC<Props> = ({ scrollProgress }) => {
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = useMedia('(max-width: 767px)', false);

  const inView = useInView(ref, {
    amount: 'all',
    once: true,
  });

  return (
    <div ref={ref} className={cvaRoot()}>
      <div className={cvaContainer()}>
        <motion.div
          className={cvaCardImageWrapper({
            side: 'left',
          })}
          initial={false}
          animate={
            isMobile
              ? {
                  y: inView ? 0 : isMobile ? '-4rem' : 0,
                  rotate: inView ? '30deg' : '15deg',
                }
              : {}
          }
          transition={{
            duration: 0.6,
            ease: 'easeOut',
          }}
          style={{
            top: isMobile ? 'unset' : `${-6 + scrollProgress * 28}rem`,
            rotate: isMobile ? 'unset' : `${15 + scrollProgress * 15}deg`,
          }}
        >
          <LazyImage src={'/images/gift-card-left.png'} alt={'Подарочная карта'} quality={100} fill />
        </motion.div>
        <motion.div
          className={cvaCardImageWrapper({
            side: 'right',
          })}
          initial={false}
          animate={
            isMobile
              ? {
                  y: inView ? 0 : isMobile ? '6rem' : '19.6rem',
                  rotate: inView ? '30deg' : '15deg',
                }
              : {}
          }
          transition={{
            duration: 0.6,
            ease: 'easeOut',
          }}
          style={{
            top: isMobile ? 'unset' : `${30 - scrollProgress * 33}rem`,
            rotate: isMobile ? 'unset' : `${15 + scrollProgress * 15}deg`,
          }}
        >
          <LazyImage src={'/images/gift-card-right.png'} alt={'Подарочная карта'} quality={100} fill />
        </motion.div>
      </div>
    </div>
  );
};

const cvaRoot = cva(['Cards-cvaRoot', ' md:relative', 'md:flex-1', 'pointer-events-none', 'z-30']);

const cvaContainer = cva([
  'Cards-cvaContainer',
  'relative sm:-top-2 md:absolute md:top-0 left-0',
  'flex items-center',
  'w-[31.2rem] h-[16.29rem] sm:w-full sm:h-[38rem] md:h-full',
]);

const cvaCardImageWrapper = cva(
  [
    'Cards-cvaCardImageWrapper',
    'absolute',
    'aspect-[34.259/22.169] w-[15.3rem] sm:w-[30rem] md:w-[28rem] lg:w-[34.259rem]',
    'z-20',
  ],
  {
    variants: {
      side: {
        left: [
          'left-3.8 z-[2] sm:left-auto sm:right-[34rem] md:right-[28.293rem] lg:right-[37.293rem]',
          'lg:-top-[6rem]',
        ],
        right: 'right-0 z-[1] sm:right-[10rem] md:right-[5.168rem] lg:right-[8.168rem] lg:top-[30rem]',
      },
    },
  }
);
