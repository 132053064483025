'use client';
import { AnimatedTranslateWrapper } from '@/shared/ui/AnimatedTranslateWrapper';
import { LazyImage } from '@/shared/ui/LazyImage';
import { Link } from '@/shared/ui/Link';
import { SliderNavigationButton } from '@/shared/ui/SliderNavigationButton';
import { SliderProgress } from '@/shared/ui/SliderProgress';
import { Title } from '@/shared/ui/Title';
import { EMPTY_HREF } from '@/shared/utils/consts';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';

interface Props {
  className?: string;
  title?: string;
  imageDesktop?: string;
  imageTablet?: string;
  imageMobile?: string;
  bgColor?: string;
  isActive: boolean;
  isDelayed?: boolean;
  showButton: boolean;
  buttonText?: string;
  buttonLink?: string;
}

export const Slide1: FC<Props> = ({
  className,
  title,
  imageDesktop,
  imageTablet,
  imageMobile,
  bgColor = '#BFF6AB',
  isActive,
  isDelayed,
  showButton,
  buttonText,
  buttonLink,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const inView = useInView(ref, { once: true });

  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (isActive && inView) {
      clearTimeout(timerRef.current ?? 0);
      setIsAnimated(true);

      return () => {
        timerRef.current = setTimeout(() => setIsAnimated(false), 800);
      };
    }
  }, [isActive, inView]);

  return (
    <>
      <div ref={ref} className={twMergeExt(cvaRoot(), className)}>
        <div className={cvaDecorateBg()} style={{ background: bgColor }} />
        <div className={cvaImageMask()} style={{ background: bgColor }}>
          {imageDesktop ? (
            <LazyImage
              wrapClassName={cvaImageWrapper({
                media: 'desktop',
              })}
              className={cvaImage({
                isActive,
                isDelayed,
              })}
              src={imageDesktop}
              alt={''}
              quality={90}
              fill
            />
          ) : null}
          {imageTablet ? (
            <LazyImage
              wrapClassName={cvaImageWrapper({
                media: 'tablet',
              })}
              className={cvaImage({
                isActive,
                isDelayed,
              })}
              src={imageTablet}
              alt={''}
              quality={90}
              fill
            />
          ) : null}
          {imageMobile ? (
            <LazyImage
              wrapClassName={cvaImageWrapper({
                media: 'mobile',
              })}
              className={cvaImage({
                isActive,
                isDelayed,
              })}
              src={imageMobile}
              alt={''}
              quality={90}
              fill
            />
          ) : null}
        </div>

        <div className={cvaContent()} style={{ background: bgColor }}>
          <AnimatedTranslateWrapper
            trigger={isAnimated}
            transition={{
              duration: 0.6,
              delay: isDelayed ? 0.8 : 0.1,
            }}
          >
            <SliderProgress className={cvaSliderProgress()} />
          </AnimatedTranslateWrapper>

          {title ? (
            <Title className={cvaHeading()} as={'h2'} delay={isDelayed ? 0.8 : 0.1} trigger={isAnimated}>
              {title}
            </Title>
          ) : null}

          <div className={cvaControls()}>
            <AnimatedTranslateWrapper
              trigger={isAnimated}
              transition={{
                duration: 0.4,
                delay: isDelayed ? 1 : 0.2,
              }}
            >
              {showButton ? (
                <Link
                  className={cvaLink()}
                  color={'mediumGreen'}
                  size={'medium'}
                  hoverEffect={'highlight'}
                  disableInViewAnimation={true}
                  href={buttonLink || EMPTY_HREF}
                >
                  {buttonText}
                </Link>
              ) : null}
            </AnimatedTranslateWrapper>

            <div className={'flex gap-0.8'}>
              <AnimatedTranslateWrapper
                trigger={isAnimated}
                transition={{
                  duration: 0.4,
                  delay: isDelayed ? 1.1 : 0.3,
                }}
              >
                <SliderNavigationButton
                  className={cvaSliderNavigationButton()}
                  disableInViewAnimation={true}
                  direction={'prev'}
                  color={'outlinedOnWhite'}
                />
              </AnimatedTranslateWrapper>
              <AnimatedTranslateWrapper
                trigger={isAnimated}
                transition={{
                  duration: 0.4,
                  delay: isDelayed ? 1.2 : 0.4,
                }}
              >
                <SliderNavigationButton
                  className={cvaSliderNavigationButton()}
                  disableInViewAnimation={true}
                  direction={'next'}
                  color={'outlinedOnWhite'}
                />
              </AnimatedTranslateWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const cvaRoot = cva([
  'Slide1-cvaRoot',
  'relative',
  'flex flex-col lg:flex-row',
  'overflow-hidden lg:min-h-[67.4rem]',
  'h-full',
]);

// Соотношение сторон:
// Мобилка 360/372
// Планшет 714/738
// Десктоп 714/738

// Размеры:
// Мобилка 360px-767px: от 360x372 до 767x792.56px
// Планшет 768px-1023px: от 614x634 до 1023x1057.38px
// Десктоп 1024px-1920px: от 714x738 до 1071x1107

const cvaImageMask = cva([
  'Slide1-cvaImageMask',
  'order-2 lg:order-none',
  'rounded-[4rem] sm:rounded-[6.4rem] lg:rounded-[0]',
  'overflow-hidden lg:overflow-auto',
]);

const cvaDecorateBg = cva(['Slide1-cvaDecorateBg']);

const cvaImageWrapper = cva(
  [
    'Slide1-cvaImageWrapper',
    'absolute top-[50%] left-0 translate-y-[-50%]',
    'aspect-[360/372] w-full h-full sm:aspect-[720/674] md:aspect-[720/674] lg:aspect-[720/674]',
  ],
  {
    variants: {
      media: {
        desktop: 'hidden lg:block rounded-[0]',
        tablet: 'hidden sm:block lg:hidden rounded-[0]',
        mobile: 'block sm:hidden rounded-[4rem]',
      },
    },
  }
);

const cvaImage = cva(
  [
    'Slide1-cvaImage',
    'object-cover object-center',
    'transition-transform duration-[1.2s] ease-[cubic-bezier(0.71,0,0.16,1)]',
    'overflow-hidden',
    'rounded-[4rem] sm:rounded-[6.4rem]',
  ],
  {
    variants: {
      isActive: {
        true: 'scale-100',
        false: 'scale-[0.9]',
      },
      isDelayed: {
        true: ' delay-[0.6s]',
        false: '',
      },
    },
  }
);

const cvaContent = cva([
  'Slide1-cvaContent',
  'relative z-[1]',
  'flex flex-col',
  'px-2.4 py-3.2 pb-2.4 sm:px-3.2 sm:py-4 md:px-4.2 lg:pt-6 lg:pb-9.2 lg:pr-[3.5rem] lg:pl-[3.5rem] xl:pt-6 xl:pb-9.2 xl:pr-[11.8rem] xl:pl-[8.8rem]',
  'rounded-[4rem] sm:rounded-[6.4rem] lg:rounded-[0]',
  'overflow-hidden lg:overflow-auto',
]);

const cvaSliderProgress = cva(['Slide1-cvaSliderProgress']);

const cvaHeading = cva(['Slide1-cvaHeading', 'max-w-[50.8rem]', 'mt-1.6 sm:mt-2.4', 'font-secondary text-h1']);

const cvaControls = cva(['Slide1-cvaControls', 'flex gap-3.2', 'mt-2.4 sm:mt-4 lg:mt-auto']);

const cvaLink = cva(['Slide1-cvaLink', 'px-3.2 pb-0.2', 'text-bodyMSemibold']);

const cvaSliderNavigationButton = cva(['Slide1-cvaSliderNavigationButton', 'hidden md:flex', 'bg-transparent']);
