'use client';
import { FC } from 'react';
import { cva } from 'class-variance-authority';
import { LazyImage } from '@/shared/ui/LazyImage';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { useDimensions } from '@/shared/hooks/useDimensions';
import { useMediaValues } from '@/shared/hooks/useMediaValues';

interface Props {
  className?: string;
}

export const AnimatedBg: FC<Props> = ({ className }) => {
  const [ref, dimensions] = useDimensions();

  const gap = useMediaValues(
    {
      0: '1.2rem',
      768: '4.2rem',
    },
    0
  );

  return (
    <div className={twMergeExt(cvaRoot(), 'floating', className)} style={{ gap }}>
      {new Array(2).fill(null).map((_, index) => (
        <div
          key={index}
          ref={index === 0 ? ref : undefined}
          className={cvaWrapper()}
          style={{
            minHeight: '100%',
            maxHeight: dimensions.scrollHeight || 'none',
            height: dimensions.scrollHeight || '100%',
          }}
        >
          <LazyImage
            wrapClassName={cvaImage({ order: 1 })}
            className={'rounded-[1.6rem]'}
            src={'/images/mocks/socials-section-1.png'}
            alt={''}
            fill
          />
          <LazyImage
            wrapClassName={cvaImage({ order: 2 })}
            className={'rounded-[1.6rem]'}
            src={'/images/mocks/socials-section-2.png'}
            alt={''}
            fill
          />
          <LazyImage
            wrapClassName={cvaImage({ order: 3 })}
            src={'/images/mocks/socials-section-3.png'}
            className={'rounded-[1.6rem]'}
            alt={''}
            fill
          />
          <LazyImage
            wrapClassName={cvaImage({ order: 4 })}
            src={'/images/mocks/socials-section-4.png'}
            className={'rounded-[1.6rem]'}
            alt={''}
            fill
          />
          <LazyImage
            wrapClassName={cvaImage({ order: 5 })}
            src={'/images/mocks/socials-section-5.png'}
            className={'rounded-[1.6rem]'}
            alt={''}
            fill
          />
          <LazyImage
            wrapClassName={cvaImage({ order: 6 })}
            src={'/images/mocks/socials-section-6.png'}
            className={'rounded-[1.6rem]'}
            alt={''}
            fill
          />
        </div>
      ))}

      <style jsx>
        {`
          .floating {
            animation: socialsBgFloating 12s linear infinite;
          }

          @keyframes socialsBgFloating {
            0% {
              transform: translateY(0px);
            }
            100% {
              transform: translateY(calc(${-dimensions.scrollHeight}px - ${gap}));
            }
          }
        `}
      </style>
    </div>
  );
};

const cvaRoot = cva(['AnimatedBg-cvaRoot', 'absolute top-0 left-0', 'flex flex-col', 'w-full h-full']);

const cvaWrapper = cva(['AnimatedBg-cvaWrapper', 'relative', 'grow-0 shrink-0 w-full h-full']);

/*
 * Для корректной работы этого прикола все изображения должны позиционироваться относительно верхнего края.
 * Отрицательные значения top не допускаются.
 */
const cvaImage = cva(['SocialsSection-cvaImage', 'absolute inset-[initial]', '[&>img]:rounded-[1.6rem]'], {
  variants: {
    order: {
      1: 'top-0 right-6 sm:right-[12rem] md:right-[37.3rem] aspect-[24/28.4] w-[12rem] sm:w-[16rem] md:w-[20rem] lg:w-[24rem]',
      2: 'top-[23.2rem] right-4.4 sm:right-5.4 aspect-[18.8/18.8] w-[10.8rem] sm:w-[14.8rem] md::w-[16.8rem] lg:w-[18.8rem]',
      3: 'top-[30rem] sm:top-[60.9rem] right-[20.8rem] lg:right-[29.8rem] aspect-[18/32] w-[10rem] sm:w-[14rem] md:w-[18rem]',
      4: 'top-[54rem] right-4 sm:right-auto sm:top-[76.8rem] sm:left-[12.2rem] lg:left-[41.2rem] aspect-[16.4/16.4] w-[10.4rem] sm:w-[14.4rem] md:w-[16.4rem]',
      5: 'top-[49.8rem] left-3.6 aspect-[18.8/18.8] w-[11.8rem] sm:w-[15.8rem] md:w-[18.8rem]',
      6: 'top-[7.9rem] left-[2rem] sm:left-[14.1rem] aspect-[22.2/22.2] w-[11.2rem] sm:w-[15.2rem] md:w-[19.2rem] lg:w-[22.2rem]',
    },
  },
});
