'use client';
import { ICity } from '@/shared/api/getShops/types';
import { Container } from '@/shared/ui/Container';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { Store } from '@/widgets/our-stores-section/ui/Store';
import StoresMap from '@/widgets/StoresMap/ui/StoresMap';
import { cva } from 'class-variance-authority';
import { AnimatePresence, useInView } from 'framer-motion';
import { FC, useRef, useState } from 'react';

interface Props {
  className?: string;
  shops: ICity[];
}

export const OurStoresSection: FC<Props> = ({ className, shops }) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });
  const mid = Math.ceil(shops.length / 2);
  const firstHalf = shops?.slice(0, mid);
  const secondHalf = shops.slice(mid);
  const [showStoresMap, setShowStoresMap] = useState<string | null>(null);

  return (
    <>
      <Container ref={ref} className={twMergeExt(cvaRoot(), className)} variant={'large'}>
        <Title className={cvaHeading()} trigger={inView}>
          Наши магазины <span className={'text-cMediumGreen'}>Aloe Smart</span>
        </Title>

        <div className={cvaContainer()}>
          <div className={cvaStores()}>
            <div className={cvaStoresInner()}>
              {firstHalf.map((shop, idx) => (
                <Store
                  key={shop.id}
                  title={shop.name}
                  badge={shop.shops_count}
                  image={shop.shops[0]?.photos[0]}
                  onClick={() => {
                    setShowStoresMap(shop.name);
                  }}
                  position={idx === firstHalf.length - 1 ? 'bottom' : 'top'}
                />
              ))}
            </div>
          </div>
          <div className={cvaStores()}>
            <div className={twMergeExt(cvaStoresInner(), 'border-t border-cBlack16 md:border-none')}>
              {secondHalf.map((shop, idx) => (
                <Store
                  key={shop.id}
                  title={shop.name}
                  badge={shop.shops_count}
                  image={shop.shops[0]?.photos[0]}
                  onClick={() => {
                    setShowStoresMap(shop.name);
                  }}
                  position={idx === secondHalf.length - 1 ? 'bottom' : 'top'}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
      <AnimatePresence>
        {showStoresMap ? (
          <StoresMap shops={shops} activeCity={showStoresMap} onClose={() => setShowStoresMap(null)} onMainPage />
        ) : null}
      </AnimatePresence>
    </>
  );
};

const cvaRoot = cva(['OurStoresSection-cvaRoot', 'my-6.4 sm:my-[10.4rem]']);

const cvaHeading = cva([
  'OurStoresSection-cvaHeading',
  'max-w-[56rem]',
  'mx-auto',
  'font-secondary text-h1 text-center',
]);

const cvaContainer = cva([
  'OurStoresSection-cvaContainer',
  'flex flex-col md:flex-row md:gap-1.2',
  'py-2 sm:py-3.2 mt-2.4 sm:mt-7.2 rounded-[2rem] sm:rounded-[3.2rem] md:py-0 md:rounded-none',
  'bg-cLightGrey md:bg-transparent',
]);

const cvaStores = cva([
  'OurStoresSection-cvaStores',
  'flex-1 overflow-hidden',
  'px-2 sm:px-3.2 md:p-3.2 rounded-[3.2rem]',
  'md:bg-cLightGrey',
]);

const cvaStoresInner = cva(['OurStoresSection-cvaStoresInner']);
