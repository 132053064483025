'use client';
import { LeftSlider } from '@/features/analogues-compare/ui/LeftSlider';
import { RightSlider } from '@/features/analogues-compare/ui/RightSlider';
import { RightSliderChangeAnimation } from '@/features/analogues-compare/ui/RightSliderChangeAnimation';
import { useMediaValues } from '@/shared/hooks/useMediaValues';
import { Container } from '@/shared/ui/Container';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { motion, useInView, useScroll } from 'framer-motion';
import { useLenis } from 'lenis/react';
import { FC, ReactNode, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useMedia, useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';
import { useHeaderStore } from '@/shared/store/headerStore';

interface Props {
  className?: string;
  title?: string;
  left: {
    contentContainerClassName?: string;
    sliderClassName?: string;
    sliderControlsClassName?: string;
    heading?: string;
    slidesSlot?: ReactNode;
  };
  right: {
    contentContainerClassName?: string;
    heading?: string;
    slidesSlot?: ReactNode | ((leftSliderActiveIndex: number) => ReactNode[][]);
  };
}

export const AnaloguesCompare: FC<Props> = ({ className, title, left, right }) => {
  const ref = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const [stickyMeasureRef, stickyMeasure] = useMeasure();
  const stickyBottom: number = useMediaValues(
    {
      0: 0,
      1024: -20,
    },
    0
  );

  const { height: windowHeight } = useWindowSize();

  const { scrollYProgress } = useScroll({
    axis: 'y',
    target: placeholderRef,
    offset: [`${stickyBottom + stickyMeasure.height - windowHeight}px`, 'end'],
  });

  const inView = useInView(ref, { once: true });

  const [leftSliderActiveIndex, setLeftSliderActiveIndex] = useState(0);

  const lenis = useLenis();
  const scrollToStartOnLeftSliderSlideChange = useMedia('(min-width: 1024px)', false);

  const setAllowChangeVisibility = useHeaderStore((state) => state.setAllowChangeVisibility);

  const onLeftSliderSlideChange = (realIndex: number) => {
    setLeftSliderActiveIndex(realIndex);

    if (
      scrollToStartOnLeftSliderSlideChange &&
      lenis &&
      ref.current &&
      stickyRef.current &&
      ref.current.getBoundingClientRect().top < 0
    ) {
      setAllowChangeVisibility(false);
      setTimeout(() => {
        if (ref.current) {
          lenis.scrollTo(
            scrollY + ref.current.getBoundingClientRect().top + stickyMeasure.height + stickyBottom - windowHeight,
            {
              lock: true,
              immediate: true,
            }
          );
        }

        setAllowChangeVisibility(true);
      });
    }
  };

  return (
    <Container ref={ref} className={twMergeExt(cvaRoot(), className)} variant={'large'}>
      <div className={'relative'}>
        {/* Плейсхолдер для правильной высоты */}
        <div ref={placeholderRef} className={'hidden md:block md:h-full md:invisible'}>
          {title ? (
            <Container variant={'small'}>
              <Title as={'h2'} className={cvaHeading()} trigger={inView}>
                {title}
              </Title>
            </Container>
          ) : null}
          <div className={cvaGridContainer()}>
            <div className={cvaColContainer()}>
              <div
                className={cvaHeader({
                  side: 'right',
                })}
              >
                <div className={cvaColHeading()}>{right.heading ?? ''}</div>
              </div>
              <div className={twMergeExt(cvaContentContainer(), right.contentContainerClassName)}>
                <RightSlider className={'md:relative'} sliderWrapperClassName={'md:relative'}>
                  {typeof right.slidesSlot === 'function'
                    ? right.slidesSlot(leftSliderActiveIndex)[leftSliderActiveIndex]
                    : right.slidesSlot}
                </RightSlider>
              </div>
            </div>
          </div>
        </div>

        <div className={'flex w-full md:absolute md:top-0 md:left-0 md:items-end md:h-full'}>
          <motion.div
            ref={mergeRefs([stickyRef, stickyMeasureRef])}
            className={'md:sticky'}
            style={{ bottom: stickyBottom }}
          >
            {title ? (
              <Container className={'px-0 sm:px-3.2 md:px-4.2 lg:px-5.6'} variant={'small'}>
                <Title as={'h2'} className={cvaHeading()} trigger={inView}>
                  {title}
                </Title>
              </Container>
            ) : null}

            <div className={cvaGridContainer()}>
              <div className={cvaColContainer()}>
                <div
                  className={cvaHeader({
                    side: 'left',
                  })}
                >
                  <div className={cvaColHeading()}>{left.heading ?? ''}</div>
                </div>
                <div className={twMergeExt(cvaContentContainer(), left.contentContainerClassName)}>
                  <LeftSlider
                    className={left.sliderClassName}
                    controlsClassName={left.sliderControlsClassName}
                    onSlideChange={onLeftSliderSlideChange}
                  >
                    {left.slidesSlot}
                  </LeftSlider>
                </div>
              </div>

              <div className={cvaColContainer()}>
                <div
                  className={cvaHeader({
                    side: 'right',
                  })}
                >
                  <div className={cvaColHeading()}>{right.heading ?? ''}</div>
                </div>
                <div className={twMergeExt(cvaContentContainer(), right.contentContainerClassName)}>
                  <RightSliderChangeAnimation leftSliderActiveIndex={leftSliderActiveIndex}>
                    <RightSlider scrollYProgress={scrollYProgress}>
                      {typeof right.slidesSlot === 'function'
                        ? right.slidesSlot(leftSliderActiveIndex)[leftSliderActiveIndex]
                        : right.slidesSlot}
                    </RightSlider>
                  </RightSliderChangeAnimation>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </Container>
  );
};

const cvaRoot = cva(['AnaloguesCompare-cvaRoot']);

const cvaHeading = cva(['AnaloguesCompare-cvaHeading', 'max-w-[65.4rem]', 'font-secondary text-h2']);

const cvaGridContainer = cva([
  'AnaloguesCompare-cva',
  'grid grid-cols-1 gap-0.8 md:grid-cols-2 sm:gap-1.2',
  'mt-2.4 sm:mt-4.8',
]);

const cvaColContainer = cva(['AnaloguesCompare-cvaColContainer', 'relative', 'flex flex-col']);

const cvaContentContainer = cva([
  'AnaloguesCompare-cvaContentContainer',
  'relative',
  'flex-1 h-full lg:h-[70%] overflow-hidden',
  '-mt-4.8 rounded-[2rem] sm:rounded-[3.2rem]',
  'bg-cLightGrey',
]);

const cvaHeader = cva(
  [
    'AnaloguesCompare-cvaHeader',
    'flex justify-center',
    'pt-1.2 sm:pt-1.6 pb-6.4 rounded-t-[2rem] sm:rounded-t-[3.2rem]',
  ],
  {
    variants: {
      side: {
        left: 'bg-cDarkGreen',
        right: 'bg-cMediumGreen',
      },
    },
  }
);

const cvaColHeading = cva(['AnaloguesCompare-cvaColHeading', 'text-bodyLSemibold text-cWhite']);
