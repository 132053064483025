import { Ref, useEffect, useRef, useState } from 'react';

interface Dimensions {
  scrollWidth: number;
  scrollHeight: number;
  offsetWidth: number;
  offsetHeight: number;
  clientWidth: number;
  clientHeight: number;
}

export const useDimensions = (): [Ref<any>, Dimensions] => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    scrollWidth: 0,
    scrollHeight: 0,
    offsetWidth: 0,
    offsetHeight: 0,
    clientWidth: 0,
    clientHeight: 0,
  });

  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const handler = () => {
        setDimensions({
          scrollWidth: ref.current!.scrollWidth ?? 0,
          scrollHeight: ref.current!.scrollHeight ?? 0,
          offsetWidth: ref.current!.offsetWidth ?? 0,
          offsetHeight: ref.current!.offsetHeight ?? 0,
          clientWidth: ref.current!.clientWidth ?? 0,
          clientHeight: ref.current!.clientHeight ?? 0,
        });
      };

      handler();
      window.addEventListener('resize', handler);

      return () => {
        window.removeEventListener('resize', handler);
      };
    }
  }, []);

  return [ref, dimensions];
};
