'use client';
import { IBanner } from '@/shared/api/getMainPage/types';
import { Container } from '@/shared/ui/Container';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { Slide1 } from '@/widgets/banners-slider/ui/Slide1';
import { cva } from 'class-variance-authority';
import { FC, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

interface Props {
  className?: string;
  slides: IBanner[];
}

export const BannersSlider: FC<Props> = ({ className, slides }) => {
  const [isDelayed, setIsDelayed] = useState(true);

  const onSetTransition = (swiper: SwiperType) => {
    // @ts-expect-error: нет типа
    if (!swiper.touchEventsData.isTouched) {
      swiper.wrapperEl.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.09, 1)';
    } else {
      swiper.wrapperEl.style.transitionTimingFunction = 'initial';
    }
  };

  return (
    <Container className={twMergeExt(cvaRoot(), className)} variant={'full'}>
      <Swiper
        className={cvaSlider()}
        modules={[Autoplay]}
        slidesPerView={1}
        spaceBetween={12}
        speed={1200}
        autoplay={{ delay: 10000 }}
        onSetTransition={onSetTransition}
        onSlideChange={() => setIsDelayed(false)}
        watchSlidesProgress
      >
        {slides?.map((slide, idx) => (
          <SwiperSlide key={idx} className={cvaSlide()}>
            {({ isActive }) => (
              <Slide1
                title={slide.preview_text}
                imageDesktop={slide.preview_picture_lg}
                imageTablet={slide.preview_picture_md}
                imageMobile={slide.preview_picture_sm}
                bgColor={slide.background_color}
                showButton={slide.show_button}
                buttonText={slide.text_button}
                buttonLink={slide.button_link}
                isActive={isActive}
                isDelayed={isDelayed}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

const cvaRoot = cva(['BannersSlider-cvaRoot', 'relative']);

const cvaSlider = cva(['BannersSlider-cvaSlider', 'items-stretch']);

const cvaSlide = cva(['h-auto lg:h-full']);
