import { LazyImage } from '@/shared/ui/LazyImage';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

interface Props {
  className?: string;
  image: string;
  title: string;
  description: string;
  author: string;
  isActive?: boolean;
}

export const BloggerCard: FC<Props> = ({ className, image, title, description, author, isActive }) => {
  const variantsImageContainer = {
    enter: (direction: string) => ({
      opacity: 1,
      width: '100%',
      top: direction === 'bottom' ? 'auto' : '0',
      bottom: direction === 'bottom' ? '0' : 'auto',
      clipPath: 'polygon(100% 0%,100% 0%,100% 100%,100% 100%)',
    }),
    center: {
      opacity: 1,
      width: '100%',
      top: '0',
      bottom: '0',
      clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
    },
    exit: (direction: string) => ({
      opacity: 1,
      width: '100%',
      top: direction === 'bottom' ? '0' : 'auto',
      bottom: direction === 'bottom' ? 'auto' : '0',
    }),
  };

  const variantsImage = {
    enter: (direction: string) => ({
      opacity: 1,
      top: direction === 'bottom' ? 'auto' : '0',
      bottom: direction === 'bottom' ? '0' : 'auto',
    }),
    center: {
      top: '0',
      bottom: '0',
      opacity: 1,
    },
    exit: (direction: string) => ({
      opacity: 1,
      top: direction === 'bottom' ? 'auto' : '0',
      bottom: direction === 'bottom' ? '0' : 'auto',
    }),
  };

  return (
    <div className={twMergeExt(cvaRoot(), className)}>
      {image ? (
        <AnimatePresence mode={'popLayout'} initial={false}>
          <motion.div
            key={`imageContainer${title}`}
            variants={variantsImageContainer}
            initial="enter"
            animate={isActive ? 'center' : 'enter'}
            exit="exit"
            transition={{
              duration: 0.8,
              ease: 'easeInOut',
            }}
            className={cvaImageOverflow()}
          >
            <motion.div
              key={`image${title}`}
              variants={variantsImage}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                duration: 0.8,
                ease: 'easeInOut',
              }}
              className={cvaImageOverflowInner()}
            >
              <LazyImage className={'object-center object-cover'} src={image} alt={''} fill />
            </motion.div>
          </motion.div>
        </AnimatePresence>
      ) : null}

      <div
        className={
          'flex flex-col justify-between gap-x-3.2 gap-y-1.6 mt-2.4 sm:mt-1.6 px-2 sm:px-3.2 lg:flex-row lg:mt-3.2'
        }
      >
        <div className={cvaHeading()}>{title}</div>
        <div className={cvaDescr()}>
          {description}
          <span className={cvaAuthor()}>{author}</span>
        </div>
      </div>
    </div>
  );
};

const cvaRoot = cva(['BlogerCard-cvaRoot', 'sm:pb-2.4']);

const cvaHeading = cva(['BloggerCard-cvaHeading', 'font-secondary text-h3']);

const cvaDescr = cva(['BloggerCard-cvaDescr', 'md:max-w-[30.7rem]', 'text-[1.6rem] leading-[1.48em] text-cBlack80']);

const cvaAuthor = cva(['BloggerCard-cvaAuthor', 'block mt-[2rem] sm:mt-[2.5rem]', 'font-semibold text-cBlack']);

const cvaImageOverflow = cva([
  'BloggerCard-cvaImageOverflow',
  'absolute left-0 bottom-0 overflow-hidden',
  'relative aspect-[312/175] w-full sm:aspect-auto sm:h-[38.7rem] mt-7.2 sm:mt-8.8',
]);

const cvaImageOverflowInner = cva(['BloggerCard-cvaImageOverflowInner', 'absolute left-0 overflow-hidden', 'w-full']);
