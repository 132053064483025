import { Button } from '@/shared/ui/Button';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import Link from 'next/link';
import { FC } from 'react';
import ArrowTRIcon from '~/icons/arrow-tr.svg';
import CloudIcon from '../assets/cloud.svg';
import StarIcon from '../assets/star.svg';
import YandexIcon from '../assets/yandex.svg';

interface Props {
  className?: string;
}

export const YandexReviewsLink: FC<Props> = ({ className }) => {
  return (
    <Link
      className={twMergeExt(cvaRoot(), className)}
      href={
        'https://yandex.ru/maps/54/yekaterinburg/chain/aloe_smart/14022169323/?ll=60.573333%2C56.812409&sll=61.849725%2C57.482759&sspn=84.023438%2C31.727881&z=13'
      }
      target="_blank"
    >
      <div className={cvaIcon()}>
        <CloudIcon
          className={' w-[11.6rem] h-[10.4rem] text-cLightGrey sm:w-[12.6rem] sm:h-[11.3rem] sm:text-cWhite'}
        />
        <YandexIcon className={cvaYandexIcon()} />
        <div className={cvaIconLabel()}>Рейтинг на Яндекс</div>
      </div>

      <div className={cvaRatingContainer()}>
        <StarIcon className={cvaStarIcon()} />
        <div className={cvaRating()}>5.0</div>
      </div>

      <Button
        className={cvaButton()}
        rootWrapperClassName={cvaButtonWrapper()}
        color={'darkGreyOnGrey'}
        hoverEffect={'highlight'}
        yandexReviewLink
      >
        <ArrowTRIcon className={'w-1.6 h-1.6 text-cBlack'} />
      </Button>
    </Link>
  );
};

const cvaRoot = cva([
  'YandexReviewsLink-cvaRoot',
  'relative sm:absolute sm:top-[13.4rem] right-0',
  'flex justify-center sm:justify-between items-center',
  'w-[16.8rem] h-[10.4rem] sm:w-[24.6rem] sm:h-[14.8rem]',
  'mx-auto sm:pr-2.4 sm:rounded-l-[6.4rem]',
  'sm:bg-cLightGrey',
  'sm:translate-x-6.4',
  'transition-transform duration-300 sm:hoctive:translate-x-0',
]);

const cvaIcon = cva(['YandexReviewsLink-cvaIcon', 'absolute sm:top-1/2 sm:left-3.5', 'sm:-translate-y-1/2']);

const cvaYandexIcon = cva([
  'YandexReviewsLink-cvaYandexIcon',
  'absolute top-1.2 sm:top-0.9 left-1/2',
  '-translate-x-1/2',
]);

const cvaIconLabel = cva([
  'YandexReviewsLink-cvaIconLabel',
  'absolute top-5.9 sm:top-5.7 left-1/2',
  'w-7',
  'text-labelS text-cBlack48 text-center',
  '-translate-x-1/2',
]);

const cvaRatingContainer = cva([
  'YandexReviewsLink-cvaRatingContainer',
  'absolute left-[0.22rem] top-[3.345rem] sm:-left-[0.525rem] sm:top-[4.809rem]',
  'w-[5.3rem] h-2.4 sm:w-[6.65rem] sm:h-3',
  'rounded-[0.8rem]',
  'bg-cMediumGreen',
  'text-bodyMSemibold',
  '-rotate-[15deg]',
]);

const cvaStarIcon = cva([
  'YandexReviewsLink-cvaStarIcon',
  'absolute top-1/2 left-0.5 sm:left-[0.729rem]',
  'w-1.6 h-1.6 sm:w-2.1 sm:h-2.1',
  '-translate-y-1/2',
]);

const cvaRating = cva(['YandexReviewsLink-cvaRating', 'absolute top-0 right-0.8', 'text-bodyLSemibold text-cWhite']);

const cvaButton = cva(['YandexReviewsLink-cvaButton', 'hidden sm:inline', 'p-1.2 ml-auto rounded-[1.6rem]']);

const cvaButtonWrapper = cva(['YandexReviewsLink-cvaButtonWrapper', 'hidden sm:inline', 'ml-auto']);
