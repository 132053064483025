'use client';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { typograf } from '@/shared/utils/typograf';
import BgDesktopIcon from '@/widgets/gift-section/assets/bg-desktop.svg';
import BgMobileIcon from '@/widgets/gift-section/assets/bg-mobile.svg';
import BgTabletIcon from '@/widgets/gift-section/assets/bg-tablet.svg';
import { Cards } from '@/widgets/gift-section/ui/Cards';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import useMeasure from 'react-use-measure';
import AngleIcon from '~/icons/cards_angle.svg';

interface Props {
  className?: string;
}

export const GiftSection: FC<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });
  const [measureRef, rootMeasureRefBounds] = useMeasure({
    scroll: true,
  });
  const [scrollProgress, setScrollProgress] = useState<number>(0);

  useEffect(() => {
    if (rootMeasureRefBounds.top - 250 <= 0) {
      const st = window.pageYOffset - (rootMeasureRefBounds.top - 250 + window.pageYOffset);

      const scrollHeight = rootMeasureRefBounds.height - 250;

      if (st < scrollHeight) {
        const progress = st / (scrollHeight / 100) / 100;
        setScrollProgress(progress);
      } else {
        setScrollProgress(1);
      }
    } else {
      setScrollProgress(0);
    }
  }, [rootMeasureRefBounds.top]);

  return (
    <div className={cvaRootWrapper()}>
      <AngleIcon className={cvaAngleIcon()} />
      <BgDesktopIcon className={twMergeExt(cvaBgIcon(), 'hidden md:block')} />
      <BgTabletIcon className={twMergeExt(cvaBgIcon(), 'hidden sm:block md:hidden')} />
      <BgMobileIcon className={twMergeExt(cvaBgIcon(), 'sm:hidden')} />
      <Container ref={mergeRefs([ref, measureRef])} className={twMergeExt(cvaRoot(), className)} variant={'full'}>
        <div className={cvaBg({ variant: 1 })}></div>
        <div className={cvaBg({ variant: 2 })}></div>
        <div className={cvaBg({ variant: 3 })}></div>
        <div className={cvaBg({ variant: 4 })}></div>
        <div className={cvaBg({ variant: 5 })}></div>

        <div className={cvaContainer()}>
          <div className={cvaLeftContainer()}>
            <Title as={'h2'} trigger={inView} className={cvaHeading()}>
              Подари возможность выбирать
            </Title>
            <div className={cvaDescr()}>{typograf.execute('Мы знаем, как выбрать идеальный подарок.')}</div>
            <Button
              className={cvaButton()}
              color={'mediumGreen'}
              size={'medium'}
              hoverEffect={'highlight'}
              href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/certificate/'}
              prefetch={false}
            >
              Оформить сертификат
            </Button>
          </div>

          <Cards scrollProgress={scrollProgress} />
        </div>
      </Container>
    </div>
  );
};

const cvaRootWrapper = cva(['GiftSection-cvaRootWrapper', 'relative']);

const cvaRoot = cva(['GiftSection-cvaRoot', 'relative', 'my-6.4 sm:my-[10.4rem]', 'lg:overflow-hidden']);

const cvaBg = cva(['GiftSection-cvaBg', 'absolute ', 'rounded-[4rem] sm:rounded-[6.4rem]', 'bg-cLightGreen'], {
  variants: {
    variant: {
      1: 'w-[32.2222%] h-[36.6460%] left-0 top-0 sm:w-[77.3611%] sm:h-[77.8302%] rounded-br-none',
      2: 'w-[74.4444%] h-[36.6460%] right-0 top-0 rounded-bl-none sm:top-auto sm:left-0 sm:bottom-0 sm:w-[29%] md:w-[25.2083%] sm:h-[30.8962%] sm:rounded-tr-none',
      3: 'w-[100%] h-[36.6460%] right-0 top-[31.6460%] sm:w-[29%] sm:top-0 md:w-[25.2083%] sm:h-[41.7453%] sm:rounded-bl-none',
      4: 'w-[74.4444%] h-[36.6460%] left-0 bottom-0 sm:left-auto sm:right-0 sm:w-[77.3611%] sm:h-[66.9811%] sm:rounded-tl-none',
      5: 'w-[32.2222%] h-[36.6460%] bottom-0 right-0  sm:hidden ',
    },
  },
});

const cvaContainer = cva(['GiftSection-cvaContainer', 'relative', 'flex flex-col md:flex-row', 'pb-7 sm:pb-0']);

const cvaAngleIcon = cva([
  'GiftSection-cvaAngleIcon',
  'absolute',
  'right-[28.8rem] top-[-0.4rem]',
  'hidden lg:block',
  'z-50',
  'rotate-180',
]);

const cvaLeftContainer = cva(['GiftSection-cvaLeftContainer', 'px-2.4 py-4 pb-2.8 sm:p-5.6', 'z-60']);

const cvaHeading = cva(['GiftSection-cvaHeading', 'lg:max-w-[70rem] md:max-w-[50rem]', 'font-secondary text-h2']);

const cvaDescr = cva([
  'GiftSection-cvaDescr',
  'max-w-[40.6rem]',
  'mt-1.4 sm:mt-2.4',
  'text-bodyM sm:text-[1.6rem] sm:leading-[1.48em] text-cBlack80',
]);

const cvaButton = cva([
  'GiftSection-cvaButton',
  'flex items-center',
  'px-2 sm:px-3.2 pb-0.2 mt-2.4 md:mt-9.4',
  'max-w-[25rem]',
  'text-bodyMSemibold',
]);

const cvaBgIcon = cva([
  'Cards-cvaBgIcon',
  'absolute -top-1.9 left-1 sm:left-auto sm:-top-3 sm:right-8 md:-top-2.8 md:right-[4.15rem]',
  'w-[33.594rem] h-[50.809rem] sm:w-[62.8rem] sm:h-[72.6rem] md:h-[47.98rem] md:w-[91.65rem]',
  'md:[&>path:nth-child(1)]:hidden lg:[&>path:nth-child(1)]:block',
  'md:[&>path:nth-child(2)]:hidden lg:[&>path:nth-child(2)]:block',
  'md:[&>path:nth-child(5)]:hidden lg:[&>path:nth-child(5)]:block',
  'z-10',
  'pointer-events-none',
]);
